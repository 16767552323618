export interface QuestionInterface {
    label: Array<string>,
    answers: Array<string>,
    rightAnswer: number,
    buttonLabel: string,
};

const questions: Array<QuestionInterface> = [
    {
        label: [
            "Peut-on utiliser le Lait de coco",
            "dans les préparations salées et sucrées ?",
        ],
        answers: [
            "oui",
            "non",
        ],
        rightAnswer:0,
        buttonLabel: "QUESTION 2/3 >",
    },
    {
        label: [
            "Les recettes des Pâtes de curry",
            "Thai Kitchen sont-elles Vegan ?",
        ],
        answers: [
            "oui",
            "non",
        ],
        rightAnswer:0,
        buttonLabel: "QUESTION 3/3 >",
    },
    {
        label: [
            "Quel est l’animal célébré",
            "pour le Nouvel an chinois 2022 ?",
        ],
        answers: [
            "Le rat",
            "Le tigre",
            "Le buffle",
        ],
        rightAnswer: 1,
        buttonLabel: "VALIDER",
    },    
];

export default questions;