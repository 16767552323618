import ReactGA from 'react-ga';

import api from '../../services/api';

import { useEffect, useRef, useContext } from 'react';

import {AppContext, AppContextInterface} from '../../contexts';

import { setVideoShowStatus, setPhase } from "../../actions";

import Layout from '../Layout';
import Home from '../Pages/Home';
import Download from '../Pages/Download';
import Modal from '../Modal';
import Catering from '../ModalContents/Catering';
import Video from '../ModalContents/Video';
import Game from '../Game';

import './styles.scss';
import LegalNotices from '../Pages/LegalNotices';
import Rules from '../Pages/Rules';

function App() {
  const {state, dispatch} = useContext<AppContextInterface>(AppContext);
  const { page, catering, game, isVideoShown } = state;

  const appRef = useRef<HTMLDivElement>(null);

  useEffect(
      ()=>{
          if (appRef && appRef.current){
              appRef.current.scrollIntoView();
          }
      },
      [page, game.active, isVideoShown],
  );

  useEffect(
    () => {
      ReactGA.initialize('UA-72581200-30');
    },
    [],
  );  

  useEffect(
    () => {
      ReactGA.pageview(page);
    },
    [page],
  );

  useEffect(
    () => {
      const apiCall = async () => {
        const response = await api.getPhase();
        console.log(response);
        if (response.status === 1){
          dispatch(setPhase(response.phase));
        }
      };
      apiCall();
    },
    [dispatch],
);

  return (
        <div className="app" ref={appRef}>     
            <Layout>
                {page === 'home' && <Home />}
                {page === 'download' && <Download />}      
                {page === 'legal' && <LegalNotices />}
                {page === 'rules' && <Rules />}        
            </Layout>            
            {
              !catering && (
                <Modal 
                  title={<>CHOISISSEZ VOTRE TYPE DE RESTAURATION,<br/>POUR DÉCOUVRIR VOTRE CONTENU PERSONNALISÉ :</>}
                >
                  <Catering />
                </Modal>
              )
            }
            {
              game.active && <Game />
            }
            {
              isVideoShown && 
                <Modal 
                  onClose={() => dispatch(setVideoShowStatus(false))}
                >
                  <Video />
                </Modal>
            }
        </div>
  );
}

export default App;
