const Rules = () => (
    <div className='misc'>            
        <h1 className='misc__title'>
            Réglement
        </h1>
        <div className="misc__content">
            <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iusto iure soluta libero obcaecati, odio doloribus alias optio suscipit rerum perferendis. Aliquid laboriosam, assumenda cupiditate sunt hic mollitia. Blanditiis, nemo doloremque!
            </p>
            
        </div>
    </div>
);

export default Rules;