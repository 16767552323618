const LegalNotices = () => (
    <div className='misc'>            
        <h1 className='misc__title'>
            Mentions légales
        </h1>
        <div className="misc__content">
            <p>
                Ce site est la propriété de la société McCORMICK France S.A.S. au capital de 19 572 874 € dont le Siège Social est situé :<br />
                Site Agroparc - 315 Rue Marcel Demonque - 84917 Avignon Cedex 9<br />
                Tél : 04 32 73 65 65 - Fax : 04 32 73 65 00<br />
                RCS Avignon 622 980 027 - Siret : 622 980 027 00180 APE 1084 Z<br />
            </p>
            <p>
                Conception réalisation du site<br />
                PMKR – 19 rue St Eloi - 13010 Marseille<br />
                Pour toute question, écrire à : serviceconsoducros@fr.mccormick.com<br />
                N° de TVA intercommunautaire FR 62 622 980 027<br />
            </p>
            <p>
                L’hébergement du site est réalisé par OVH.
            </p>
        </div>
    </div>
);

export default LegalNotices;