const CommercialDownload = () => (
    <div className='download download--commercial'>            
            <h1 className='download__title'>
                découvrez tous nos menus<br />
                <span>nouvel an chinois</span> à télécharger !
            </h1>

            <div className="menu menu--1">
                <h2 className='menu__title'>
                    MENU 1
                </h2>
                <ul className='menu__meals'>
                    <li className='menu__meal'>
                        BROCHETTES DE CREVETTES SATAY
                    </li>
                    <li className='menu__meal'>
                        DOS DE CABILLAUD EN CROûTE DE CURRY ROUGE,<br />
                        MOUSSELINE DE PATATES DOUCES à LA VANILLE
                    </li>
                    <li className='menu__meal'>
                        BAO SUCRé, CHANTILLY COCO ET MANGUE
                    </li>
                </ul>
                <a href="/pdf/restauration_commerciale_menu1.pdf" target="_blank" className='menu__download'>TÉLÉCHARGER</a>
            </div>

            <div className="menu menu--right menu--2">
                <h2 className='menu__title'>
                    MENU 2
                </h2>
                <ul className='menu__meals'>
                    <li className='menu__meal'>
                        FRITES DE GAMBAS & TARTARE MANGUE/AVOCAT
                    </li>
                    <li className='menu__meal'>
                        CURRY ROUGE D’AGNEAU, MENTHE,<br />
                        LéGUMES PRINTANIERS & POMMES DE TERRE
                    </li>
                    <li className='menu__meal'>
                        CARPACCIO D’ANANAS, <br />
                        CHANTILLY CHOCOLAT/CREME DE COCO
                    </li>
                </ul>
                <a href="/pdf/restauration_commerciale_menu2.pdf" target="_blank" className='menu__download'>TÉLÉCHARGER</a>
            </div>

            <div className="menu menu--3">
                <h2 className='menu__title'>
                    MENU 3
                </h2>
                <ul className='menu__meals'>
                    <li className='menu__meal'>
                        SALADE DE CRUDITés VINAIGRETTE THAI
                    </li>
                    <li className='menu__meal'>
                        BURGER DE DINDE THAI
                    </li>
                    <li className='menu__meal'>
                        Mousse au Lait de Coco<br />
                        et aux fruits 
                    </li>
                </ul>
                <a href="/pdf/restauration_commerciale_menu3.pdf" target="_blank" className='menu__download'>TÉLÉCHARGER</a>
            </div>

            <div className="menu menu--right menu--4">
                <h2 className='menu__title'>
                    MENU 4
                </h2>
                <ul className='menu__meals'>
                    <li className='menu__meal'>
                        Salade de carottes Thai, sauce Sweet chili                        
                    </li>
                    <li className='menu__meal'>
                        CURRY D’ÉPEAUTRE, BROCHETTE DE TOFU<br />
                        & ANANAS SéSAME
                    </li>
                    <li className='menu__meal'>
                        SMOOTHIE COCO & BROCHETTE D'ANANAS<br />
                        AU SÉSAME DORé
                    </li>
                </ul>
                <a href="/pdf/restauration_commerciale_menu4.pdf" target="_blank" className='menu__download'>TÉLÉCHARGER</a>
            </div>
        </div>
);

export default CommercialDownload;