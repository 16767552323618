import { useAppContext } from "../../../contexts";

import { changePage, setVideoShowStatus } from "../../../actions";

import imageVideo from "../../../img/video.png";

const Home = () => {
    const {dispatch} = useAppContext();
    return (
        <div className='home'>
                <div className='home-top'>
                    <h1 className='home-top__title'>
                        <span>Inspirez-vous grâce à</span> nos menus à télécharger
                    </h1>
                    <h2 className='home-top__subtitle'>
                        spécial Nouvel An chinois !
                    </h2>
                </div>
                <div className='home-menu'>
                    <a onClick={() => dispatch(changePage('download'))} className='home-menu__button'>DÉCOUVRIR LES MENUS</a>
                </div>
                <div className='home-video'>
                    <h2 className='home-video__title'>
                        vidéo recettes
                    </h2>
                    <div className="home-video__video">
                        <a className="home-video__link" onClick={() => dispatch(setVideoShowStatus(true))}>
                            <img src={imageVideo} alt="vidéo recettes" />
                        </a>                                             
                    </div>
                    <p className='home-video__text'>
                        Le chef Hugues Marrec du restaurant<br />
                        la Camarette (84) vous dévoile<br />
                        2 recettes originales et gourmandes<br />
                        spéciales Nouvel an Chinois.                        
                    </p>
                </div>
                <div className='home-products'>
                    <h2 className='home-products__title'>
                        <span>RETROUVEZ</span> TOUTES LES SAVEURS<br />
                        <strong>DE LA CUISINE THAÏ !</strong>
                    </h2>

                    <div className='link-products'>
                        <a href='https://www.mccormickfoodservice.fr/marques-produits/thai-kitchen/thai-kitchen/lait-de-coco-1l' target='_blank' className='link-products__laitcoco'/>
                        <a href='https://www.mccormickfoodservice.fr/marques-produits/thai-kitchen/thai-kitchen/sweet-chili-sauce-600-ml' target='_blank' className='link-products__chili'/>
                        <a href='https://www.mccormickfoodservice.fr/marques-produits/thai-kitchen/thai-kitchen/creme-de-coco-1l' target='_blank' className='link-products__cremecoco'/>
                        <a href='https://www.mccormickfoodservice.fr/marques-produits/thai-kitchen/thai-kitchen/pate-de-curry-jaune' target='_blank' className='link-products__curry'/>
                        <a href='https://www.mccormickfoodservice.fr/marques-produits/thai-kitchen/thai-kitchen/nouilles-de-riz' target='_blank' className='link-products__nouilles'/>
                    </div>
                </div> 

            </div>
    );
};

export default Home;