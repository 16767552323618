import axios from 'axios';

const ApiService = {
    async play(data : {[key:string]: any}): Promise<{[key:string]: any}> {

        const formData = new FormData();

        for (let key in data) {
            formData.append(key, data[key]);
        }

        const response = await axios.post('https://thai-kitchen-pro.production-pmkr.fr/back/index.php/main/play', formData);
        return response.data;
    },

    async getPhase(): Promise<{[key:string]: any}> {
        const response = await axios.post('https://thai-kitchen-pro.production-pmkr.fr/back/index.php/main/phase');
        return response.data;
    },
};

export default ApiService;