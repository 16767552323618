const CollectiveDownload = () => (
    <div className='download download--collective'>            
            <h1 className='download__title'>
                découvrez tous nos menus<br />
                <span>nouvel an chinois</span> à télécharger !
            </h1>

            <div className="menu menu--1">
                <h2 className='menu__title'>
                    MENU 1
                </h2>
                <ul className='menu__meals'>
                    <li className='menu__meal'>
                        SALADE DE CONCOMBRE & CRêME DE COCO
                    </li>
                    <li className='menu__meal'>
                        DOS DE CABILLAUD AU SOJA<br />
                        & NOUILLES SAUCE CURRY/COCO
                    </li>
                    <li className='menu__meal'>
                        LASSI & TARTARE DE MANGUE/BASILIC
                    </li>
                </ul>
                <a href="/pdf/restauration_collective_menu1.pdf" target="_blank" className='menu__download'>TÉLÉCHARGER</a>
            </div>

            <div className="menu menu--right menu--2">
                <h2 className='menu__title'>
                    MENU 2
                </h2>
                <ul className='menu__meals'>
                    <li className='menu__meal'>
                        CROQUETTEs DE CRABE<br />
                        & GUACAMOLE PIMENTé
                    </li>
                    <li className='menu__meal'>
                        SUPRêMES DE VOLAILLE<br />
                        SAUCE SATAY & RIZ BRUN
                    </li>
                    <li className='menu__meal'>
                        MOUSSE AU LAIT DE COCO & FRUITS
                    </li>
                </ul>
                <a href="/pdf/restauration_collective_menu2.pdf" target="_blank" className='menu__download'>TÉLÉCHARGER</a>
            </div>

            <div className="menu menu--3">
                <h2 className='menu__title'>
                    MENU 3
                </h2>
                <ul className='menu__meals'>
                    <li className='menu__meal'>
                        CRèME DE CAROTTES AU CURRY
                    </li>
                    <li className='menu__meal'>
                        BLANQUETTE DE VEAU CURRY ET CITRON VERT
                    </li>
                    <li className='menu__meal'>
                        VERRINE FRAISE & MOUSSE COCO
                    </li>
                </ul>
                <a href="/pdf/restauration_collective_menu3.pdf" target="_blank" className='menu__download'>TÉLÉCHARGER</a>
            </div>

            <div className="menu menu--right menu--4">
                <h2 className='menu__title'>
                    MENU 4
                </h2>
                <ul className='menu__meals'>
                    <li className='menu__meal'>
                        SALADE DE NOUILLES DE RIZ AU CITRON VERT
                    </li>
                    <li className='menu__meal'>
                        CURRY VéGéTARIEN
                    </li>
                    <li className='menu__meal'>
                        PANNA COTTA COCO & AGRUMES
                    </li>
                </ul>
                <a href="/pdf/restauration_collective_menu4.pdf" target="_blank" className='menu__download'>TÉLÉCHARGER</a>
            </div>
        </div>
);

export default CollectiveDownload;